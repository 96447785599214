import React, {
  useState,
  useEffect
} from 'react';

const Firebase = props => {
  const {
    children
  } = props;
  const INITIAL_STATE = {
    isLoading: true,
    firebase: null
  };
  const [state, setState] = useState(INITIAL_STATE);
  const {
    isLoading,
    firebase
  } = state;
  useEffect(() => {
    const init = async () => { // debugger;
      const {
        REACT_APP_FIREBASE_API_KEY: apiKey,
        REACT_APP_FIREBASE_AUTH_DOMAIN: authDomain,
        REACT_APP_FIREBASE_DATABASE_URL: databaseURL,
        REACT_APP_FIREBASE_PROJECT_ID: projectId,
        REACT_APP_FIREBASE_STORAGE_BUCKET: storageBucket,
        REACT_APP_FIREBASE_MESSAGING_SENDER_ID: messagingSenderId,
        REACT_APP_FIREBASE_APP_ID: appId,
        REACT_APP_FIREBASE_MEASUREMENT_ID: measurementId
      } = process.env;
      const config = {
        apiKey,
        authDomain,
        databaseURL,
        projectId,
        storageBucket,
        messagingSenderId,
        appId,
        measurementId
      };
      const {
        default: firebaseApp
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-firebase-app' */'firebase/compat/app');
      await import(/* webpackPrefetch: true, webpackChunkName: 'vendors-firebase-app-check' */'firebase/compat/app-check');
      const {
        default: FunctionsRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-functions' */'../Firebase/Repositories/Functions');
      const {
        default: AuthenticationRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-authentication' */'../Firebase/Repositories/Authentication');
      const {
        default: StorageRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-storage' */'../Firebase/Repositories/Storage');
      const {
        default: UsersRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-users' */'../Firebase/Repositories/Users');
      const {
        default: LanguageResourcesRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-language-resources' */'../Firebase/Repositories/LanguageResources');
      const {
        default: FAQListRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-fAQ-list' */'../Firebase/Repositories/FAQList');
      const {
        default: NotificationListRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-notification-list' */'../Firebase/Repositories/NotificationList');
      const {
        default: UserNotificationsRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-user-notifications' */'../Firebase/Repositories/UserNotifications');
      const {
        default: UserConnectionsRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-user-connections' */'../Firebase/Repositories/UserConnections');
      const {
        default: GroupsRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-groups' */'../Firebase/Repositories/Groups');
      const {
        default: UserGroupsRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-user-groups' */'../Firebase/Repositories/UserGroups');
      const {
        default: JournalListRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-journal-list' */'../Firebase/Repositories/JournalList');
      const {
        default: UserJournalsRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-user-journals' */'../Firebase/Repositories/UserJournals');
      const {
        default: LunarPhasesRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-lunar-phases' */'../Firebase/Repositories/LunarPhases');
      const {
        default: SettingsRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-settings' */'../Firebase/Repositories/Settings');
      const {
        default: NgaPumanawaEWaruListRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-nga-pumanawa-e-waru-list' */'../Firebase/Repositories/NgaPumanawaEWaruList');
      const {
        default: ReportsRepository
      } = await import(/* webpackPrefetch: true, webpackChunkName: 'app-firebase-repositories-reports' */'../Firebase/Repositories/Reports');
      const firebase = {
        app: firebaseApp.apps.length
          ? firebaseApp.app()
          : firebaseApp.initializeApp(config),
        functions: new FunctionsRepository(firebaseApp),
        authentication: new AuthenticationRepository(firebaseApp),
        storage: new StorageRepository(firebaseApp),
        users: new UsersRepository(firebaseApp),
        languageResources: new LanguageResourcesRepository(firebaseApp),
        faq: new FAQListRepository(firebaseApp),
        notifications: new NotificationListRepository(firebaseApp),
        userNotifications: new UserNotificationsRepository(firebaseApp),
        userConnections: new UserConnectionsRepository(firebaseApp),
        groups: new GroupsRepository(firebaseApp),
        userGroups: new UserGroupsRepository(firebaseApp),
        journals: new JournalListRepository(firebaseApp),
        userJournals: new UserJournalsRepository(firebaseApp),
        lunarPhases: new LunarPhasesRepository(firebaseApp),
        settings: new SettingsRepository(firebaseApp),
        ngaPumanawaEWarus: new NgaPumanawaEWaruListRepository(firebaseApp),
        reports: new ReportsRepository(firebaseApp)
      };
      setState(s => ({
        ...s,
        isLoading: false,
        firebase
      }));
    };
    if (isLoading) {
      init();
    }
    return () => { };
  }, [props, isLoading]);
  return <>
    {
      isLoading
        ? null
        : children(firebase)
    }
  </>;
};

export default Firebase;
